<script>
import {segmentTypeForPrompt, mergeTypeForPrompt} from '@/main';

export default {
  name: 'translateStart',
  data() {
    return {
      username: '',
      password: '',
      uid: '',
      // isMaster: false,
      usePrompt: 'no',
      isLogin: false,
      droppedTransFile: null,
      droppedGlossaryFile: null,
      isDragging: false,
      transText: '',
      transPdf: '',
      settingData: {
        projectCode: '',
        transFileName: '',
        transFileCount: '',
        transFileUnit: '',
        sourceLanguage: 'default',
        targetLanguage: 'default',
        selectedField: 'general',
        translateEngine: 'deepl',
        glossaryAvailability: 'N',
        translationType: 'True',
        glossaryFileName: '',
        glossarySourceLang: '',
        glossaryTargetLang: '',
        promptType: false,
        sgmtContent: segmentTypeForPrompt,
        mrgContent: mergeTypeForPrompt,
      },
      isLoading: false,
      isErrorBoxVisible: false,
      errorMsgsObjArr: [
        {errorCode: 100, title: `유저 검증에 실패하였습니다.`, sub: ``},
        {errorCode: 200, title: `프로젝트 조회에 실패하였습니다.`, sub: ``},
        {errorCode: 201, title: `출발어가 지원하지 않는 언어입니다.`, sub: `현재는 한국어, 영어, 중국어 일본어, 스페인어, 필리핀어만 분석이 가능해요.`},
        {errorCode: 300, title: `파일 업로드에 실패했습니다.`, sub: `잠시 후 다시 시도해주세요.`},
        {errorCode: 400, title: `너무 긴 문장이 있습니다.`, sub: `원본 파일에는 한 문장이 과도하게 길게 작성되어 있는 부분이 있습니다.`},
        {errorCode: 401, title: `서식 재현에 실패했습니다.`, sub: ``},
        {errorCode: 402, title: `내용이 없는 빈 파일입니다.`, sub: ``},
        {errorCode: 403, title: `파일 안의 글자 수가 너무 많습니다.`, sub: ``},
        {errorCode: 500, title: `gpt 결과 검증 실패`, sub: ``},
        {errorCode: 501, title: `결과 json 파싱 에러`, sub: ``},
        {errorCode: 502, title: `지속적인 API 에러`, sub: `엔진 에러가 지속되어 중단되었습니다. 잠시 후 다시 시도해주세요. 문제가 지속된다면 관리자에게 문의하세요.`},
        {errorCode: 999, title: `기타 서버 에러`, sub: `문제가 지속된다면 관리자에게 문의하세요.`},
        {errorCode: 1000, title: `파일 업로드가 취소되었습니다.`, sub: ``},
        {errorCode: 0, title: `예기치 못한 에러가 발생했습니다.`, sub: `업로드하신 파일과 함께 관리자에게 문의를 남겨주세요.`},
      ]
    }
  },
  methods: {
    showErrorAlert(errorCode, receivedSub) {
      const errorMsg = this.errorMsgsObjArr.find(error => error.errorCode === errorCode);

      if(errorMsg && errorCode !== 400) {
        alert(`${errorMsg.title}\n${errorMsg.sub}`)
      } else if(errorCode === 400) {
        alert(`${errorMsg.title}\n${errorMsg.sub}\n${receivedSub}`)
      }
      else {
        alert(`예기치 못한 에러가 발생했습니다. \n 업로드하신 파일과 함께 관리자에게 문의를 남겨주세요.`)
      }
    },
    initPrompt() {
      if (this.usePrompt === 'segment') {
        if (this.settingData.sgmtContent === segmentTypeForPrompt) {
          console.log('이미 초기 상태입니다.');
        } else {
          this.settingData.sgmtContent = segmentTypeForPrompt;
        }
      } else if (this.usePrompt === 'merge') {
        if (this.settingData.mrgContent === mergeTypeForPrompt) {
          console.log('이미 초기 상태입니다.');
        } else {
          this.settingData.mrgContent = mergeTypeForPrompt;
        }
      }
    },
    handleFileChange(event) {
      const files = event.target.files
      this.handleFiles(files)
    },
    handleGlossaryFileChange(event) {
      const files = event.target.files
      this.handleGlossaryFiles(files)
    },
    handleDragOver(event) {
      event.preventDefault()
      this.isDragging = true
    },
    handleDragLeave() {
      this.isDragging = false
    },
    handleDrop(event) {
      event.preventDefault()
      this.isDragging = false
      const files = event.dataTransfer.files
      this.handleFiles(files)
    },
    handleFiles(files) {
      const maxSize = 50 * 1024 * 1024 // 50MB (byte 단위)
      if (files && files.length > 0) {
        const file = files[0]
        if (file.size > maxSize) {
          alert('파일 크기가 제한을 초과했습니다. (50MB)')
          return
        }
        // 파일 크기가 제한 내에 있는 경우 추가 처리 로직
        // console.log('파일 크기가 제한 내에 있습니다.')
        // 파일 처리 로직
        this.droppedTransFile = file
        console.log(this.droppedTransFile)
        // console.log(files)
        this.postDataToServer()
      }
    },
    handleGlossaryFiles(files) {
      const maxSize = 50 * 1024 * 1024 // 50MB (byte 단위)
      if (files && files.length > 0) {
        const file = files[0]
        if (file.size > maxSize) {
          alert('파일 크기가 제한을 초과했습니다. (50MB)')
          return
        }
        // 파일 크기가 제한 내에 있는 경우 추가 처리 로직
        // console.log('파일 크기가 제한 내에 있습니다.')
        // 파일 처리 로직
        this.droppedGlossaryFile = file
        // console.log(files)
      }
    },
    openFileInput() {
      this.$refs.fileInput.click()
    },
    openGlossaryFileInput() {
      this.$refs.glossaryFileInput.click()
    },
    formatFileSize(size) {
      if (size === 0) return '0 Bytes'
      const units = ['Bytes', 'KB', 'MB', 'GB', 'TB']
      const i = Math.floor(Math.log2(size) / 10)
      return `${(size / Math.pow(1024, i)).toFixed(2)} ${units[i]}`
    },
    resetFile() {
      this.droppedTransFile = null
    },
    async postDataToServer() {
      const formData = new FormData();
      formData.append('file', this.droppedTransFile);
      formData.append('uid', this.uid);

      try {
        const response = await this.$axios.post('https://transcreation.lexcodeapi.com/tc/upload_project_file/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        });
        if (response.data.result === true) {
          // 서버에서 응답 데이터가 있는 경우
          if (response.data.src_lang === 'Korean') {
            this.settingData.targetLanguage = 'English'
          } else {
            this.settingData.targetLanguage = 'Korean'
          }
          if (response.data.count_unit === 'char') {
            this.settingData.transFileUnit = '자'
          } else {
            this.settingData.transFileUnit = '단어'
          }
          this.settingData.projectCode = response.data.project_code
          this.settingData.sourceLanguage = response.data.src_lang
          this.settingData.transFileCount = response.data.count_num.toLocaleString();
          this.settingData.transText = response.data.trans_text
          this.settingData.transPdf = response.data.pdf_path

          this.settingData.transFileName = this.droppedTransFile.name
        }
      } catch (error) {
        this.showErrorAlert(error.response.data.error_code, error.response.data.err[0]);
        console.error(error);
      } finally {
        // this.settingData.transFileName = this.droppedTransFile.name
      }
    },
    async saveSettingInfo() {
      this.isLoading = true
      const formData = new FormData();
      formData.append('uid', this.uid);
      formData.append('project_code', this.settingData.projectCode);
      formData.append('src_lang', this.settingData.sourceLanguage);
      formData.append('tgt_lang', this.settingData.targetLanguage);
      formData.append('field', this.settingData.selectedField);
      formData.append('have_glo', this.settingData.glossaryAvailability);

      if (this.usePrompt === 'segment') {
        formData.append('prompt', this.settingData.sgmtContent);
      } else if (this.usePrompt === 'merge') {
        formData.append('prompt', this.settingData.mrgContent);
      } else {
        formData.append('prompt', '');
      }

      if (this.droppedGlossaryFile) {
        formData.append('glo_file', this.droppedGlossaryFile);
      } else {
        formData.append('glo_file', '');
      }
      formData.append('trans_engine', this.settingData.translateEngine);
      try {
        const response = await this.$axios.post('https://transcreation.lexcodeapi.com/tc/create_trans_data_for_edit', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        });
        if (response.data.result) {
          this.isLoading = false
          this.$router.push({
            path: '/translate',
            query: {project_code: this.settingData.projectCode || ''}, // 값이 없는 경우 빈 문자열로 설정
          });
        } else {
          this.isLoading = false
        }
      } catch (error) {
        this.showErrorAlert(error.response.data.error_code);
        this.isLoading = false
        // 오류 처리
        console.error(error);
      }
    }
  },
  mounted() {
    const storedValue = sessionStorage.getItem('loginYN');
    const storedUID = sessionStorage.getItem('uID');
    if (storedValue === 'true' && storedUID > 0) {
      this.isLogin = true
      this.uid = storedUID
    } else {
      this.isLogin = false
    }
  }
}
</script>


<template>
  <div class="tc main">
    <div class="process_wrap">
      <div class="step active">
        <p class="num">1</p>
        <p class="process">설정</p>
      </div>
      <div class="step">
        <p class="num">2</p>
        <p class="process">번역</p>
      </div>
      <div class="step">
        <p class="num">3</p>
        <p class="process">Creation</p>
      </div>
      <div class="step">
        <p class="num">4</p>
        <p class="process">추출</p>
      </div>
    </div>
    <div class="container">
      <h1 class="title">생성형 AI 솔루션이 필요한 파일을 업로드 해주세요.</h1>
      <fieldset class="file">
        <div class="drop-area" :class="{ 'drag-over': isDragging, 'isFile': droppedTransFile != null }"
             @dragover.prevent="handleDragOver" @dragleave="handleDragLeave" @drop="handleDrop">
          <template v-if="droppedTransFile">
            <div class="file_info">
              <img src="../assets/img/file_icon.png" alt="file icon"/>
              <a :href="droppedTransFile.url" target="_blank">{{ droppedTransFile.name }}</a>
              <p class="trans_unit_count">{{ settingData.transFileCount }}{{ settingData.transFileUnit }}</p>
            </div>
            <div class="file_info">
              <p class="file_size">{{ formatFileSize(droppedTransFile.size) }}</p>
              <img v-if="droppedTransFile" @click="resetFile" class="reset-button" src="../assets/img/close.png" alt="close"/>
            </div>
          </template>

          <template v-else>
            <img src="../assets/img/upload-cloud.png" alt="upload cloud"/>
            <p class="dragdrop">drag & drop here</p>
            <p class="available">docx, xlsx, pptx 형식만 지원 가능합니다.<br/>최대 파일 첨부 크기는 50MB입니다.</p>
            <p @click="openFileInput" class="plus">파일 직접선택</p>
          </template>
        </div>
        <input type="file" ref="fileInput" style="display: none" @change="handleFileChange"/>
      </fieldset>
      <div class="pretrans_wrap" v-if="droppedTransFile">
        <h2>Pre-Translate</h2>
        <div class="pretrans">
          {{ settingData.transText }}
        </div>
      </div>
      <div class="after-upload">
        <h2>설정</h2>
        <div class="row first" :class="{ disable: settingData.transFileName === '' }">
          <div>
            <fieldset>
              <label>번역엔진 / Model</label>
              <select v-model="settingData.translateEngine">
                <option value="claude_opus_custom_self_direction">Claude opus self direction</option>
                <option value="claude_sonnet_custom_self_direction">Claude sonnet self direction</option>
                <option value="claude_opus">Claude3 OPUS</option>
                <option value="claude_sonnet">Claude3 SONNET</option>
                <option value="claude_opus_merge">Claude3 OPUS Merge</option>
                <option value="claude_sonnet_merge">Claude3 SONNET Merge</option>
                <option value="claude35_sonnet">Claude3.5 SONNET</option>
                <option value="deepl">DeepL</option>
                <option value="google">GOOGLE</option>
                <option value="gpt35_del_style">GPT-3.5 Del Style</option>
                <option value="gpt35merge">GPT-3.5 Merge</option>
                <option value="gpt35">GPT-3.5 Turbo</option>
                <option value="gpt35_custom_self_direction">GPT-3.5 self direction</option>
                <option value="gpt4_del_style">GPT-4 Del Style</option>
                <option value="gpt4merge">GPT-4 Merge</option>
                <option value="gpt4">GPT-4 Turbo</option>
                <option value="gpt4_custom_self_direction">GPT-4 self direction</option>
                <option value="gpt4_del_style_custom_mcst">GPT-4 문체부</option>
                <option value="gpt4_del_style_custom_law">GPT-4 법률</option>
                <option value="gpt4o">GPT-4o</option>
                <option value="gpt4o_merge">GPT-4o Merge</option>
                <option value="papago">Papago</option>
                <option value="claude_opus_custom_hurom">휴롬엘에스 opus</option>
                <option value="gpt4o_custom_hurom">휴롬엘에스 GPT4o</option>
                <option value="gpt4_custom_hurom">휴롬엘에스 GPT4</option>
                <!-- <option value="dummy">dummy</option> -->
              </select>
            </fieldset>
          </div>
          <div>
            <fieldset>
              <label>출발어 / SRC language</label>
              <select v-model="settingData.sourceLanguage">
                <option value="default">자동감지</option>
                <option value="English">영어(미국)</option>
                <option value="Korean">한국어</option>
                <option value="Japanese">일본어</option>
                <option value="Chinese(Simplified)">중국어 간체</option>
                <option value="Spanish">스페인어</option>
                <option value="Tagalog"
                        :disabled="settingData.translateEngine === 'deepl' || settingData.translateEngine === 'papago'">
                  필리핀어
                </option>
              </select>
            </fieldset>
          </div>
          <div>
            <fieldset>
              <label>도착어 / Target language</label>
              <select v-model="settingData.targetLanguage">
                <option value="default">선택</option>
                <option value="English">영어(미국)</option>
                <option value="Korean">한국어</option>
                <option value="Japanese">일본어</option>
                <option value="Chinese(Simplified)">중국어 간체</option>
                <option value="Spanish">스페인어</option>
                <option value="Tagalog"
                        :disabled="settingData.translateEngine === 'deepl' || settingData.translateEngine === 'papago'">
                  필리핀어
                </option>
              </select>
            </fieldset>
          </div>
        </div>
        <div class="row second" :class="{ disable: settingData.transFileName === '' }">
          <div>
            <fieldset>
              <label>분야 / Field</label>
              <select v-model="settingData.selectedField">
                <option value="general">일반</option>
                <option value="humanities">인문</option>
                <option value="tourism">관광</option>
                <option value="business">경영</option>
                <option value="information-technology">IT</option>
                <option value="law">법률</option>
                <option value="finance">금융</option>
                <option value="science">과학</option>
                <option value="mechanical-engineering">기계기술</option>
                <option value="environment">환경</option>
                <option value="energy">에너지</option>
                <option value="none">해당없음</option>
              </select>
            </fieldset>
          </div>
          <div>
            <fieldset class="file">
              <label>용어집파일첨부 / have glossary <a href="https://directory.lexcode.co.kr/sample_glossary.xlsx" download>샘플파일
                다운로드</a></label>
              <div class="file_info">
                <select v-model="settingData.glossaryAvailability">
                  <option value="N">없음</option>
                  <option value="Y">있음</option>
                </select>
                <p class="file_name" :class="{ disable: settingData.glossaryAvailability === 'N' }">{{
                    droppedGlossaryFile
                        ?
                        droppedGlossaryFile.name : '파일을 선택해주세요. (Upload file)'
                  }}</p>
                <p @click="openGlossaryFileInput" class="plus"
                   :class="{ disable: settingData.glossaryAvailability === 'N' }">
                  SELECT</p>
                <input type="file" ref="glossaryFileInput" style="display: none" @change="handleGlossaryFileChange"
                       :disabled="settingData.glossaryAvailability === 'N'"/>
              </div>
              <p class="description">용어집이 없을 경우, 자동으로 추출되어 보여집니다.</p>
            </fieldset>
          </div>
        </div>

        <!-- <div class="row third" :class="{ disable: settingData.transFileName == '' }" > -->
        <div class="row third" :class="{ disable: settingData.transFileName === '' }">
          <fieldset style="width: 100%;">
            <label class="prompt_add">커스텀 프롬프트 적용</label>
            <div class="prompt_radio">
              <select v-model="usePrompt">
                <option value="no" selected>적용 안 함</option>
                <option value="segment">세그먼트 분리 방식</option>
                <option value="merge">세그먼트 병합 방식</option>
              </select>
              <button class="prompt_init_btn" @click="initPrompt">프롬프트 초기화</button>
            </div>
            <div class="prompt_wrapper" v-show="usePrompt !== 'no'">
              <label>프롬프트 내용</label>
              <div>
                <textarea v-if="usePrompt === 'segment'" class="prompt_textarea"
                          v-model="settingData.sgmtContent"></textarea>
                <textarea v-else-if="usePrompt === 'merge'" class="prompt_textarea"
                          v-model="settingData.mrgContent"></textarea>
              </div>
            </div>
          </fieldset>
        </div>

        <!-- <div class="row" v-show="isMaster">
          <div>
            <fieldset>
              <label>번역 방법(개발자용) / translation type (dev)</label>
              <select v-model="settingData.translationType">
                <option value="True">Split</option>
                <option value="False">Merge</option>
              </select>
            </fieldset>
          </div>
        </div> -->

        <div>
          <template v-if="isLoading === false">
            <p class="confirm" @click="saveSettingInfo()">에디팅하기</p>
          </template>

          <template v-else>
            <p class="confirm loading">
              <span class="dot"></span>
              <span class="dot"></span>
              <span class="dot"></span>
            </p>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
